// ** TITLES ** //
export const META_HOME_TITLE =
  "Grasgevoerd en onbewerkt vlees tegen de voordeligste prijs van Nederland & België";
export const META_ABOUT_TITLE = "Over Ons";
export const META_CHECKOUT_TITLE = "Afrekenen";
export const META_COMPARE_TITLE = "Vergelijken";
export const META_CONTACT_TITLE = "Contact";
export const META_CART_TITLE = "Winkelwagen";
export const META_RESET_PASSWORD_TITLE = "Wachtwoord Resetten";
export const META_LOGIN_REGISTER_TITLE = "Inloggen - Registreren";
export const META_MY_ACCOUNT_TITLE = "Mijn Account";
export const META_ORDERS_TITLE = "Bestellingen";
export const META_NOT_FOUND_TITLE = "Niet Gevonden";
export const META_ORDER_CONFIRMED_TITLE = "Bestelling Bevestigd";
export const META_ORDER_FAILED_TITLE = "Betaling Mislukt";
export const META_WISHLIST_TITLE = "Favorieten";
export const META_PRIVACY_TITLE = "Privacy Beleid";
export const META_DELIVERY_RETURNS_TITLE = "Verzenden & retourneren";
export const META_FAQ_TITLE = "Veelgestelde Vragen";
export const META_UNSUBSCRIBE_MAILS_TITLE = "Uitschrijven voor e-mails";

// SEO Landing pages
export const META_SLAGER_TITLE = "Dé online slager van Nederland";
export const META_SLAGER_HOORN_TITLE = "Slager Hoorn";
export const META_SLAGER_MEDEMBLIK_TITLE = "Slager Medemblik";
export const META_SLAGER_ENKHUIZEN_TITLE = "Slager Enkhuizen";
export const META_IQ_GRILLS_TITLE =
  "iQ Grills: voor barbecueën zonder compromissen";
export const META_VLEES_GROOTHANDEL_TITLE =
  "Vlees groothandel: voor horeca en grootverbruik";
export const META_HORECA_TITLE = "Uw horecaslager voor Kwaliteitsvlees";
export const META_RECIPES_TITLE =
  "Recepten: inspiratie voor de lekkerste gerechten";
export const META_BLOG_TITLE = "Blog: alles over het lekkerste vlees";
export const META_VACATURES_TITLE = "Vacatures";
export const META_KERSTVLEES_TITLE =
  "Het lekkerste kerst vlees bestel je bij het Vleescentrum";

// ** DESCRIPTIONS ** //
export const META_GENERIC_DESCRIPTION =
  "Het Vleescentrum, jouw online-slagerij! Online gemakkelijk vlees bestellen in Nederland en België. (H)eerlijk en voordelig vlees gemakkelijk online besteld!";
export const META_PRODUCT_DESCRIPTION =
  "bij Het Vleescentrum. 100% Halal vlees. Voor 23.59u besteld? Morgen bezorgd.";
export const META_ABOUT_DESCRIPTION =
  "Wij zijn een online slagerij met een duidelijke missie: (h)eerlijk en ambachtelijk vlees leveren voor de beste prijs.";
export const META_CONTACT_DESCRIPTION =
  "Heb je vragen of opmerkingen over Het Vleescentrum? Vul ons contactformulier in en wij nemen direct contact met je op!";
export const META_RESET_PASSWORD_DESCRIPTION =
  "Je wachtwoord vergeten? Vraag direct een nieuwe aan via deze pagina.";
export const META_LOGIN_REGISTER_DESCRIPTION =
  "Log in bij Het Vleescentrum om al je bestellingen in te zien of om een recente bestelling opnieuw te plaatsen!";
export const META_ORDERS_DESCRIPTION =
  "Al jouw Vleescentrum bestellingen op een rij. Hier kun je ze inzien of opnieuw bestellen!";
export const META_NOT_FOUND_DESCRIPTION =
  "Oeps, je bent naar een pagina gegaan die bij ons niet (meer) bestaat.";
export const META_UNSUBSCRIBE_MAILS_DESCRIPTION =
  "Geen marketing e-mails meer ontvangen? Schrijf je hier uit.";

// SEO Landing pages
export const META_SLAGER_DESCRIPTION =
  "Wij zijn een slagerij met een duidelijk doel: (h)eerlijk en ambachtelijk vlees leveren voor de beste prijs. Iedere dag vers geleverd. Vandaag besteld = morgen in huis. Bekijk onze actieproducten voor extra voordeel!";
export const META_SLAGER_HOORN_DESCRIPTION =
  "Bestel bij Het Vleescentrum vandaag je heerlijke vleesproducten en krijg het morgen vers geleverd. 100% halal. Bekijk onze actieproducten voor extra voordeel!";
export const META_SLAGER_MEDEMBLIK_DESCRIPTION =
  "Bestel je vleesproducten bij Het Vleescentrum en krijg het morgen vers in Medemblik geleverd. Vers aan huis bezorgd. Bekijk onze actieproducten voor extra voordeel!";
export const META_SLAGER_ENKHUIZEN_DESCRIPTION =
  "Het Vleescentrum is dé online slagerij van Enkhuizen en omstreken. Iedere dag de lekkerste vleesproducten vers thuis geleverd. Bekijk onze actieproducten voor extra voordeel!";
export const META_IQ_GRILLS_DESCRIPTION =
  "Het bereiden van een mooi stuk vlees, het roken van smaakvolle vis, het grillen van verse groente, en het bakken van krokante pizza’s; met de iQ Grill is er niets dat je ervan weerhoudt om de lekkerste gerechten te bereiden.";
export const META_VLEES_GROOTHANDEL_DESCRIPTION =
  "Bent u op zoek naar de beste vlees groothandel voor uw culinaire behoeften? Zoek niet verder! Bij Het Vleescentrum leveren we met trots hoogwaardig vlees van topkwaliteit aan restaurants, cateraars, hotels en andere foodprofessionals.";
export const META_HORECA_DESCRIPTION =
  "Bent u op zoek naar een betrouwbare horeca slagerij die hoogwaardig vlees levert aan de culinaire wereld? Zoek niet verder! Bij Onze Slagerij begrijpen we de unieke behoeften van de horecasector en leveren we met trots de allerbeste vleesproducten aan restaurants, hotels, cateringbedrijven en andere horecagelegenheden.";
export const META_RECIPES_DESCRIPTION =
  "Op zoek naar inspiratie om iets lekkers op tafel te zetten? Wij hebben de lekkerste recepten met een goed stukje vlees voor je verzameld. Bekijk ze hier!";
export const META_BLOG_DESCRIPTION =
  "In onze blog lees je alles over vlees. Van de beste omstandigheden voor het perfecte biefstukje tot bereidingstips voor jouw rib-eye.";
export const META_VACATURES_DESCRIPTION =
  "Ben je op zoek naar een uitdagende baan in Enkhuizen en omstreken? Het Vleescentrum is op zoek naar jou! Bekijk onze vacatures en solliciteer vandaag nog bij dé online slagerij van Noord-Holland.";
export const META_KERSTVLEES_DESCRIPTION =
  "Maak van kerst een feest met het (h)eerlijkste vlees – ambachtelijke kwaliteit voor een smaakvolle kersttafel!";

export const META_SHOP_SLOGAN = "Overheerlijk Halal Vlees";
export const META_CATEGORY_SLOGAN =
  "Grasgevoerd en onbewerkt vlees tegen de voordeligste prijs van Nederland & België";
export const META_SLAGER_SLOGAN = "Vers Thuis Geleverd";
